<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "TERMS_&_CONDITIONS" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
</div>

<div class="dialog-body">
    <div class="page-section">
        <div>
            <div >
                <div class="row mt-3">
                    <div class="col-12">
                        <h4 class="heading-4">
                            {{ "USE_OF_TRUCKIN_CENTRAL" | translate }}
                        </h4>
                        <p class="body-long-2">
                            {{ "USE_OF_TRUCKIN_CENTRAL_DESC" | translate }}
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <h4 class="heading-4">
                            {{ "ENDORSEMENT_REQUESTS" | translate }}
                        </h4>
                        <p class="body-long-2">
                            {{ "ENDORSEMENT_REQUESTS_DESC" | translate }}
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <h4 class="heading-4">
                            {{ "LEGAL_OBLIGATIONS" | translate }}
                        </h4>
                        <p class="body-long-2">
                            {{ "LEGAL_OBLIGATIONS_DESC" | translate }}
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <h4 class="heading-4">
                            {{ "PRIVACY_POLICY" | translate }}
                        </h4>
                        <p class="body-long-2">
                            {{ "PRIVACY_POLICY_DESC_1" | translate }}
                        </p>
                        <br />
                        <p class="body-long-2">
                            {{ "PRIVACY_POLICY_DESC_2" | translate }}
                            <a href="mailTo: support@truckincentral.com"
                                >support&#64;truckincentral.com.</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

