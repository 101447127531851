import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsComponent>,
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close('close');
}
}
